var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"appBarNavigationTitle"}},[_vm._v(" "+_vm._s(_vm.navbarTitle)+" ")]),_c('portal',{attrs:{"to":"appBarNavigationAction"}},[_c('div',{staticClass:"d-flex flex-row justify-end align-center"},[_c('div',[_c('BaseBackButton',{attrs:{"route":_vm.navbarBackRouteWithoutHistory,"loading":_vm.isWaiting}}),(
                        false && !_vm.$wait.is(
                            'loading selected item'
                        ) && _vm.editMode
                    )?_c('v-tooltip',{attrs:{"bottom":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","loading":_vm.$wait.is(
                                    'deleting selected item'
                                ),"disabled":_vm.$wait.is(
                                    'deleting selected item'
                                ),"fab":_vm.$vuetify.breakpoint
                                    .smAndDown,"small":_vm.$vuetify.breakpoint
                                    .smAndDown},on:{"click":function($event){_vm.deletionDialog = true}}},on),[_c('v-icon',{attrs:{"left":_vm.$vuetify.breakpoint
                                        .mdAndUp}},[_vm._v("close")]),(
                                    _vm.$vuetify.breakpoint
                                        .mdAndUp
                                )?_c('span',[_vm._v(_vm._s(_vm.$t('delete')))]):_vm._e()],1)]}}],null,false,2851372435)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('admin.crud.deleteItem'))}})]):_vm._e(),(false && 
                        !_vm.$wait.is(
                            'loading selected item'
                        ) && _vm.selectedItem
                    )?_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"white","outlined":"","loading":_vm.$wait.is(
                                    'saving selected item'
                                ),"disabled":_vm.$wait.is(
                                    'saving selected item'
                                ),"fab":_vm.$vuetify.breakpoint
                                    .smAndDown,"small":_vm.$vuetify.breakpoint
                                    .smAndDown},on:{"click":function($event){return _vm.saveItem()}}},on),[_c('v-icon',{attrs:{"left":_vm.$vuetify.breakpoint
                                        .mdAndUp}},[_vm._v("mdi-content-save-outline")]),(
                                    _vm.$vuetify.breakpoint
                                        .mdAndUp
                                )?_c('span',[_vm._v(_vm._s(_vm.$t('save')))]):_vm._e()],1)]}}],null,false,1128463997)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('saveData'))}})]):_vm._e(),(false && _vm.$wait.is('loading selected item'))?_c('v-btn',{attrs:{"color":"white","outlined":"","loading":"","fab":_vm.$vuetify.breakpoint.smAndDown,"small":_vm.$vuetify.breakpoint.smAndDown}},[_c('v-icon',{attrs:{"left":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._v("mdi-content-save-outline")]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v(_vm._s(_vm.$t('loading')))]):_vm._e()],1):_vm._e(),(false && !_vm.createMode)?_c('v-tooltip',{attrs:{"bottom":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success","fab":_vm.$vuetify.breakpoint
                                    .smAndDown,"small":_vm.$vuetify.breakpoint
                                    .smAndDown},on:{"click":function($event){return _vm.onClickCreate()}}},on),[_c('v-icon',{attrs:{"left":_vm.$vuetify.breakpoint
                                        .mdAndUp}},[_vm._v("add")]),(
                                    _vm.$vuetify.breakpoint
                                        .mdAndUp
                                )?_c('span',[_vm._v(_vm._s(_vm.$t('new')))]):_vm._e()],1)]}}],null,false,3615659451)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('admin.crud.createNew'))}})]):_vm._e()],1)])]),_c('v-container',{staticClass:"py-0",class:{ 'mt-4': _vm.$vuetify.breakpoint.md },attrs:{"fluid":""}},[_c('v-row',{staticClass:"justify-space-arround fill-height"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('GenericItemList',{attrs:{"disableSorting":true,"items":_vm.items,"selected":_vm.selectedId},on:{"select":function($event){return _vm.onClickItem($event)}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8","lg":"9","xl":"10"}},[(!_vm.selectedItem)?[_c('div',{class:{
                            'mt-3': _vm.items.length,
                            'mt-5 pt-3': !_vm.items.length,
                        }},[_c('v-alert',{staticClass:"ml-3",attrs:{"type":"info","icon":"mdi-information-outline","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('admin.crud.selectInfo'))+" ")]),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"success"},on:{"click":function($event){return _vm.onClickCreate()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_c('span',[_vm._v(_vm._s(_vm.$t('admin.crud.createNew')))])],1)],1)]:(!_vm.$wait.is('loading selected item'))?[_vm._t("editorMask",null,{"id":_vm.selectedItem.id,"selectedItem":_vm.selectedItem,"categoryItems":_vm.categoryItems,"isModified":_vm.itemIsModified,"onSetModified":_vm.setItemIsModified,"onSaveItem":_vm.saveItem,"onCreateItem":_vm.onClickCreate})]:_vm._e()],2)],1)],1),_c('BaseConfirmationDialog',{attrs:{"title":_vm.$t('admin.crud.deleteConfirmation.headline'),"text":_vm.$t('admin.crud.deleteConfirmation.text'),"confirmationText":_vm.$t('delete')},on:{"confirm":function($event){return _vm.deleteItem()}},model:{value:(_vm.deletionDialog),callback:function ($$v) {_vm.deletionDialog=$$v},expression:"deletionDialog"}}),_c('BaseResponseDialog',{attrs:{"errorDialog":"","headline":"Error","message":"Item still in use"},model:{value:(_vm.baseResponseDialog),callback:function ($$v) {_vm.baseResponseDialog=$$v},expression:"baseResponseDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }