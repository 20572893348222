<template>
    <div>
        <portal to="appBarNavigationTitle">
            {{ navbarTitle }}
        </portal>
        <portal to="appBarNavigationAction">
            <div class="d-flex flex-row justify-end align-center">
                <div>
                    <BaseBackButton :route="navbarBackRouteWithoutHistory" :loading="isWaiting" />
                    <v-tooltip
                        v-if="
                            false && !$wait.is(
                                'loading selected item'
                            ) && editMode
                        "
                        bottom
                        color="error"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                color="error"
                                :loading="
                                    $wait.is(
                                        'deleting selected item'
                                    )
                                "
                                :disabled="
                                    $wait.is(
                                        'deleting selected item'
                                    )
                                "
                                :fab="
                                    $vuetify.breakpoint
                                        .smAndDown
                                "
                                :small="
                                    $vuetify.breakpoint
                                        .smAndDown
                                "
                                @click="deletionDialog = true"
                            >
                                <v-icon
                                    :left="
                                        $vuetify.breakpoint
                                            .mdAndUp
                                    "
                                    >close</v-icon
                                >
                                <span
                                    v-if="
                                        $vuetify.breakpoint
                                            .mdAndUp
                                    "
                                    >{{ $t('delete') }}</span
                                >
                            </v-btn>
                        </template>
                        <span
                            v-text="$t('admin.crud.deleteItem')"
                        ></span>
                    </v-tooltip>
                    <v-tooltip
                        v-if="false && 
                            !$wait.is(
                                'loading selected item'
                            ) && selectedItem
                        "
                        bottom
                        color="primary"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                color="white"
                                outlined
                                :loading="
                                    $wait.is(
                                        'saving selected item'
                                    )
                                "
                                :disabled="
                                    $wait.is(
                                        'saving selected item'
                                    )
                                "
                                :fab="
                                    $vuetify.breakpoint
                                        .smAndDown
                                "
                                :small="
                                    $vuetify.breakpoint
                                        .smAndDown
                                "
                                @click="saveItem()"
                            >
                                <v-icon
                                    :left="
                                        $vuetify.breakpoint
                                            .mdAndUp
                                    "
                                    >mdi-content-save-outline</v-icon
                                >
                                <span
                                    v-if="
                                        $vuetify.breakpoint
                                            .mdAndUp
                                    "
                                    >{{ $t('save') }}</span
                                >
                            </v-btn>
                        </template>
                        <span v-text="$t('saveData')"></span>
                    </v-tooltip>
                    <v-btn
                        v-if="false && $wait.is('loading selected item')"
                        color="white"
                        outlined
                        loading
                        :fab="$vuetify.breakpoint.smAndDown"
                        :small="$vuetify.breakpoint.smAndDown"
                    >
                        <v-icon
                            :left="$vuetify.breakpoint.mdAndUp"
                            >mdi-content-save-outline</v-icon
                        >
                        <span
                            v-if="$vuetify.breakpoint.mdAndUp"
                            >{{ $t('loading') }}</span
                        >
                    </v-btn>
                    <v-tooltip
                        v-if="false && !createMode"
                        bottom
                        color="success"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                color="success"
                                :fab="
                                    $vuetify.breakpoint
                                        .smAndDown
                                "
                                :small="
                                    $vuetify.breakpoint
                                        .smAndDown
                                "
                                @click="onClickCreate()"
                            >
                                <v-icon
                                    :left="
                                        $vuetify.breakpoint
                                            .mdAndUp
                                    "
                                    >add</v-icon
                                >
                                <span
                                    v-if="
                                        $vuetify.breakpoint
                                            .mdAndUp
                                    "
                                    >{{ $t('new') }}</span
                                >
                            </v-btn>
                        </template>
                        <span
                            v-text="$t('admin.crud.createNew')"
                        ></span>
                    </v-tooltip>
                </div>
            </div>
        </portal>
        <v-container
            fluid
            class="py-0"
            :class="{ 'mt-4': $vuetify.breakpoint.md }"
        >
            <v-row class="justify-space-arround fill-height">
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                    <GenericItemList
                        :disableSorting="true"
                        :items="items"
                        :selected="selectedId"
                        @select="onClickItem($event)"
                    />
                </v-col>
                <v-col cols="12" sm="6" md="8" lg="9" xl="10">
                    <template v-if="!selectedItem">
                        <div
                            :class="{
                                'mt-3': items.length,
                                'mt-5 pt-3': !items.length,
                            }"
                        >
                            <v-alert type="info" icon="mdi-information-outline" class="ml-3" outlined>
                                {{ $t('admin.crud.selectInfo') }}
                            </v-alert>
                            <v-btn
                                class="ml-3"
                                color="success"
                                @click="onClickCreate()"
                            >
                                <v-icon left>add</v-icon>
                                <span>{{ $t('admin.crud.createNew') }}</span>
                            </v-btn>
                        </div>
                    </template>
                    <template v-else-if="!$wait.is('loading selected item')">
                        <slot
                            name="editorMask"
                            :id="selectedItem.id"
                            :selectedItem="selectedItem"
                            :categoryItems="categoryItems"
                            :isModified="itemIsModified"
                            :onSetModified="setItemIsModified"
                            :onSaveItem="saveItem"
                            :onCreateItem="onClickCreate"
                        ></slot>
                    </template>
                </v-col>
            </v-row>
        </v-container>
        <BaseConfirmationDialog
            v-model="deletionDialog"
            :title="$t('admin.crud.deleteConfirmation.headline')"
            :text="$t('admin.crud.deleteConfirmation.text')"
            :confirmationText="$t('delete')"
            @confirm="deleteItem()"
        />
        <BaseResponseDialog
            v-model="baseResponseDialog"
            errorDialog
            headline="Error"
            message="Item still in use"
        />
    </div>
</template>

<script>
import http from '@/utils/axios';
import { v4 as uuid } from 'uuid';
import { isEqual } from 'lodash';
import ScrollHeaderMixin from '@/components/vvt/ScrollHeaderMixin';
const GenericItemList = () => import(/* webpackChunkName: "GenericItemList" */ '@/components/generic/list/ItemList.vue');

export default {
    name: 'GenericAdminCrud',
    components: {
        GenericItemList,
    },
    mixins: [ScrollHeaderMixin],
    $_veeValidate: {
        validator: 'new', // see https://github.com/baianat/vee-validate/issues/1547
    },
    i18n: {
        messages: {
            en: require('@/locales/Admin/en.json'),
            de: require('@/locales/Admin/de.json'),
        },
    },
    props: {
        routeName: {
            type: String,
            default: null,
        },
        routeAction: {
            type: String,
            default: null,
        },
        navbarTitle: {
            type: String,
            default: null,
        },
        navbarBackRouteWithoutHistory: {
            type: Object,
            default() {
                return {
                    name: 'Home',
                };
            },
        },
        fetchCalls: {
            type: Object,
            default() {
                return {
                    items: null,
                    categories: null,
                    itemById: null,
                    delete: null,
                    edit: null,
                    add: null,
                };
            },
        },
        newItemModelFields: {
            type: Object,
            default() {
                return {
                    title: null,
                };
            },
        },
    },
    data() {
        return {
            itemIsModified: false,
            categoryItems: [],
            createMode: false,
            editMode: false,
            selectedItem: null,
            selectedId: null,
            deletionDialog: false,
            items: [],
            baseResponseDialog: false,
        };
    },
    computed: {
        isWaiting() {
            return this.$wait.is(['loading main list items', 'loading selected main item',  'saving selected item']);
        },
    },
    methods: {
        setItemIsModified() {
            this.itemIsModified = true;
        },
        async fetchItems() {
            this.$wait.start('loading main list items');
            return new Promise((resolve, reject) => {
                return http(this.fetchCalls.items)
                    .then(response => response.data)
                    .then(response => {
                        this.$wait.end('loading main list items');
                        resolve(response);
                    })
                    .catch(e => {
                        this.$wait.end('loading main list items');
                        reject(e);
                    });
            });
        },
        async fetchCategories() {
            if (!this.fetchCalls.categories) {
                return [];
            }
            return new Promise((resolve, reject) => {
                return http(this.fetchCalls.categories)
                    .then(response => response.data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(e => {
                        reject(e);
                    });
            });
        },
        async fetchById(id) {
            this.$wait.start('loading selected main item');
            return new Promise((resolve, reject) => {
                return http(this.fetchCalls.itemById + id)
                    .then(response => response.data)
                    .then(response => {
                        this.itemIsModified = false;
                        this.$wait.end('loading selected main item');
                        resolve(response);
                    })
                    .catch(e => {
                        this.$wait.end('loading selected main item');
                        reject(e);
                    });
            });
        },
        async post(api, payload) {
            this.$wait.start('saving selected item');
            return new Promise((resolve, reject) => {
                return http
                    .post(api, JSON.stringify(payload), {
                        validateStatus: function(status) {
                            return [412, 200, 204].includes(status);
                        },
                    })
                    .then(response => response.data)
                    .then(response => {
                        const postStatusCode = response.code;
                        if (postStatusCode === 412) {
                            reject(response);
                        } else {
                            resolve(response);
                            this.itemIsModified = false;
                        }
                        this.$wait.end('saving selected item');
                    })
                    .catch(e => {
                        reject(e);
                    });
            });
        },
        async fetchData() {
            this.$wait.start('pageLoader');
            this.fetchLoader = true;
            if (this.fetchCalls.categories) {
                this.fetchCategories().then(x => {
                    this.categoryItems = x;
                });
            }
            this.items = await this.fetchItems();

            this.fetchLoader = false;
            this.dataFetched();
            this.$wait.end('pageLoader');
        },
        dataFetched() {
            this.initScrollHeader();
            this.handleRoute(this.$route.params);
        },
        handleRoute(routeParams) {
            const paramAction = routeParams.action;
            const paramId = routeParams.id;
            if (paramAction === this.routeAction) {
                if (paramId === 'create') {
                    this.createItem();
                } else if (paramId > 0) {
                    this.selectItem(paramId);
                } else {
                    this.closeItem();
                }
            }
        },
        onClickItem(id) {
            let route = {};
            if (id === this.selectedId) {
                route = {
                    name: this.routeName,
                    params: {
                        action: this.routeAction,
                    },
                };
            } else {
                route = {
                    name: this.routeName,
                    params: {
                        action: this.routeAction,
                        id: parseInt(id),
                    },
                };
            }
            this.$router.push(JSON.parse(JSON.stringify(route)));
        },
        selectItem(id) {
            if (id === this.selectedId) {
                this.closeItem();
            } else {
                this.openItem(id);
            }
        },
        closeItem() {
            this.selectedItem = null;
            this.selectedId = null;
            this.editMode = false;
            this.createMode = false;
        },
        openItem(id) {
            id = parseInt(id);
            this.$wait.start('loading selected item');
            this.fetchById(id)
                .then(e => {
                    this.selectedItem = e;
                    this.$wait.end('loading selected item');
                })
                .catch(() => {
                    this.$wait.end('loading selected item');
                    this.closeItem();
                });
            this.selectedId = id;
            this.editMode = true;
            this.createMode = false;
        },
        deleteItem() {
            if (this.editMode) {
                this.deleting = true;
                this.$wait.start('deleting selected item');
                this.post(this.fetchCalls.delete, this.selectedItem)
                    .then(() => {
                        this.$wait.end('deleting selected item');
                        this.$wait.end('saving selected item');
                        this.fetchItems().then(res => {
                            this.items = res;
                        });
                        this.editMode = false;
                        this.createMode = false;
                        this.selectedId = null;
                        this.selectedItem = null;
                    })
                    .catch(() => {
                        this.baseResponseDialog = true;
                        this.$wait.end('deleting selected item');
                        this.$wait.end('saving selected item');
                    });
            }
        },
        onClickCreate() {
            const route = {
                name: this.routeName,
                params: {
                    action: this.routeAction,
                    id: 'create',
                },
            };
            this.$router.push(JSON.parse(JSON.stringify(route)));
        },
        createItem() {
            this.$wait.start('loading selected item');
            this.closeItem();
            const id = uuid();
            this.selectedItem = { ...{ id: id }, ...this.newItemModelFields };
            this.createMode = true;
            this.$wait.end('loading selected item');
        },
        async saveItem() {
            this.$validator.validate().then(validationResult => {
                if (validationResult) {
                    this.$wait.start('saving selected item');
                    if (this.editMode) {
                        this.post(this.fetchCalls.edit, this.selectedItem)
                            .then((e) => {
                                this.openItem(e.id);
                                this.$wait.end('saving selected item');
                                this.fetchItems().then(res => {
                                    this.items = res;
                                });
                            })
                            .catch(() => {
                                this.$wait.end('saving selected item');
                            });
                    } else {
                        this.post(this.fetchCalls.add, this.selectedItem)
                            .then(response => {
                                this.$wait.end('saving selected item');
                                this.editMode = true;
                                this.createMode = false;
                                this.selectedId = response.id;
                                this.selectedItem.id = response.id;
                                this.openItem(response.id);
                                this.fetchItems().then(res => {
                                    this.items = res;
                                });
                            })
                            .catch(() => {
                                this.$wait.end('saving selected item');
                            });
                    }
                }
            });
        },
    },
    created() {
        this.fetchData();
    },
    watch: {
        $route(to, from) {
            if (!isEqual(to, from)) {
                this.handleRoute(to.params);
            }
        },
    },
    destroyed() {
        this.categoryItems = [];
        this.createMode = false;
        this.editMode = false;
        this.selectedItem = null;
        this.selectedId = null;
        this.deletionDialog = false;
        this.items = [];
        this.baseResponseDialog = false;
    },
};
</script>
